import axios from 'axios';

export default axios.create({
  /**
   * Dev URL
   */
  baseURL: 'https://pca-api.mmandb-staging.co.uk',
  headers: {
    'Content-type': 'application/json',
  },
});
